import React from "react";
import GettingStarted from "../GettingStarted";
import SettingsSection from "../SettingsSection";
import SettingsPaper from "../SettingsPaper";
import DomainExplainer from "./DomainExplainer";
import DomainRegistrars from "./DomainRegistrars";
import routes from "../../navigation/routes";
import StepButton from "../StepButton";
import SupportOberion from "./SupportOberion";


const txt = {
  sectionHeading: "Get a domain",
  sectionSubheading: "Information about getting a custom domain for your website",
  explainer: "Setting up a custom domain",
  registrar: "Recommended domain name registrars",
  support: "Support our open source project 💙",
  ctaButtonTxt: "Visit your website",
};


const GetWebsiteDomain = () => {
  return (
    <>
      <GettingStarted currentStep={3} />

      <SettingsSection heading={txt.sectionHeading} subheading={txt.sectionSubheading}>
        <SettingsPaper heading={txt.explainer}>
          <DomainExplainer />
        </SettingsPaper>
        <SettingsPaper heading={txt.registrar} anchorId="registrar">
          <DomainRegistrars />
        </SettingsPaper>
        <SettingsPaper heading={txt.support}>
          <SupportOberion />
        </SettingsPaper>
      </SettingsSection>

      <StepButton route={routes.user.sites.domain.to} caption={txt.ctaButtonTxt} />
    </>
  );
};
export default GetWebsiteDomain;