import React from "react";
import { Table, TableBody, TableCell, TableRow, Typography } from "@material-ui/core";
import { A_REL, OutboundLinkThemed } from "../../navigation/ExternalLink";
import styled from "styled-components";
import { CHANNELS } from "../../element/social-media/SocialMediaButton";
import githubIcon from "../../../res/icon-user-website/github.svg";
import envelope from "../../../res/icon-user-website/envelope.svg";
import twitter from "../../../res/icon-user-website/twitter.svg";
import globe from "../../../res/icon-user-website/globe.svg";
import routes from "../../navigation/routes";


const txt = {
  subheading: "Does the website solve a problem fo your? If you don't mind to spare two minutes of your time to support our free project. Help us keep running. Thanks!",
  ways: "Four ways to support us",
};
const rows = [
  {
    name: "Star our open source project on GitHub!",
    explain: "This will help others find the project easier.",
    icon: githubIcon,
    href: "https://github.com/OberionIO",
  },
  {
    name: "Subscribe to our mailing list!",
    explain: "As an early bird, we will keep you up-to-date and give special offers for our newest features once they come online.",
    icon: envelope,
    href: "#newsletter",
  },
  {
    name: "Follow us on Twitter!",
    explain: "All new features and announcements will also reach Twitter.",
    icon: twitter,
    href: "https://twitter.com/oberionio",
  },
  {
    name: "Buy a domain with our affiliate links from a domain registrar",
    explain: "We receive a compensation if you buy a domain with our links.",
    icon: globe,
    href: "#registrar",
  },
  {
    name: "Support us on Patreon",
    explain: "As an early bird, we will keep you up-to-date and give special offers for our newest features once they come online.",
    icon: CHANNELS.PATREON.icon,
    href: "https://www.patreon.com/OberionIO",
  },
];


const SupportOberion = () => {
  return (
    <>
      <Typography>{txt.subheading}</Typography>
      <TableSt>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell className="markdown">
                <ImgSt src={row.icon} alt="hh" />
              </TableCell>
              <TableCell className="markdown">
                <OutboundLinkThemed caption={row.name}
                                    href={row.href}
                                    rel={A_REL.ENDORSE}
                                    variant="subtitle1"
                                    lightTheme />
              </TableCell>
              <TableCell>
                {row.description}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableSt>
    </>
  );
};
export default SupportOberion;


const TableSt = styled(Table)`
  margin: 1rem 0;
`;
const ImgSt = styled.img`
  height: 2rem;
  width: auto;
`;