import React from "react";
import styled from "styled-components";
import { A_REL, OutboundLinkThemed } from "../../navigation/ExternalLink";


const txt = {
  a1: "Oberion does not sell domains so you need to get them from a \"domain registrar\".",
  a2: "We recommend to get a real domain as soon as possible. This way your project is always reachable under one address.",
  a3: [
    "Refer to the ",
    "GitHub Pages Docs",
    "https://docs.github.com/en/github/working-with-github-pages/managing-a-custom-domain-for-your-github-pages-site#configuring-a-subdomain",
    " for more information about setting at domain in your GitHub settings.",
  ],
};


const DomainExplainer = () => {

  return (
    <UlSt>

      <li>{txt.a1}</li>
      <li>{txt.a2}</li>
      <li>
        {txt.a3[0]}
        <OutboundLinkThemed caption={txt.a3[1]}
                            href={txt.a3[2]}
                            lightTheme
                            rel={A_REL.NOFOLLOW}
                            target={A_REL.blank} />
        {txt.a3[3]}
      </li>

    </UlSt>
  );
};
export default DomainExplainer;


const UlSt = styled.ul`
  list-style-type: square;
`;