import React from "react";
import { Table, TableBody, TableCell, TableRow, Typography } from "@material-ui/core";
import styled from "styled-components";
import { A_REL, OutboundLinkThemed } from "../../navigation/ExternalLink";


const txt = {
  affiliate: "When using these affiliate links and buying a domain we receive a compensation. This helps us keep the lights on and our servers running.",
  thanks: "Thank you for your support!",
};

function createData(name, href, description) {
  return { name, href, description };
}

const lorem = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.";
const lorem2 = "Short info about advantages of this registrar.";
const rows = [
  createData("Bluehost", "https://www.bluehost.com/", lorem2),
  createData("IONOS by 1&1", "https://www.bluehost.com/", lorem),
  createData("Hostinger", "https://www.bluehost.com/", lorem),
  createData("HostGator", "https://www.bluehost.com/", lorem),
];

const DomainExplainer = () => {
  return (
    <>
      <TableSt>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell className="markdown">
                <OutboundLinkThemed caption={row.name}
                                    href={row.href}
                                    rel={A_REL.ENDORSE}
                                    target={A_REL.blank}
                                    variant="subtitle1"
                                    lightTheme />
              </TableCell>
              <TableCell>{row.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableSt>
      <Typography component="p" variant="caption">{txt.affiliate}</Typography>
      <Typography component="p" variant="caption">{txt.thanks}</Typography>
    </>
  );
};
export default DomainExplainer;


const TableSt = styled(Table)`
  margin-bottom: 1.5rem;
`;